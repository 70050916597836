import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import BinaDetay from "./components/BinaDetay";
import AnaSayfa from "./components/AnaSayfa";
import MainHikaye from "./components/HikayeYazmaOyunu/MainHikaye";
import Projeler from "./components/Projeler";
const App = () => {
  const [binalar, setBinalar] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://haysaf.com/get/binalar")
      .then((response) => {
        // console.log(response.data);
        setBinalar(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false); // hata olsa bile yükleniyoru sonlandır
        console.log("axios veri hatası:", error);
      });
  }, []);

  return (
    <div className="theme-l4">
      <Router>
        <div className="row center theme-d1">
          <Link to='/'>
            <strong className="theme-l1 padding-small btn margin-left ">GİRİŞ</strong>
          </Link>
          <Link to="/projeler">
            <strong className="theme-l1 padding-small btn margin-left">Projeler</strong>
          </Link>
          {loading ? (
            <strong> Yükleniyor...</strong>
          ) : (
            binalar.map((bina) => (
              <Link to={`/bina/${bina.bina_id}`} key={bina.bina_id} >
                    <strong className="theme-l1 padding-small btn margin-left ">{bina.bina_adi}</strong>
              </Link>
            ))
          )}
        </div>

        <Routes>
          <Route path='/bina' element={<h4>Bir Bina Seçiniz</h4>} />
          <Route path='/projeler' element={<Projeler/>}/>
          <Route path={`/bina/:bina_id`} element={<BinaDetay />} />
          <Route path="/proje/hikaye-yazma-oyunu" element={<MainHikaye/>} />
          <Route path='/' element={<AnaSayfa/>}  />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
