import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function AnaSayfa() {
    const [data, setData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                data: [65, 59, 80, 81, 56, 55, 40],
            },
        ],
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const newData = {
                ...data,
                datasets: [
                    {
                        ...data.datasets[0],
                        data: Array.from({ length: 7 }, () => Math.floor(Math.random() * 100)),
                    },
                ],
            };
            setData(newData);
        }, 2000);

        return () => clearInterval(interval);
    }, [data]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Line Chart Example',
            },
        },
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Ana Sayfa</h2>
            <div className="bg-white p-4 rounded-lg shadow-md">
                <Line data={data} options={options} />
            </div>
        </div>
    );
}

export default AnaSayfa;