 
import imgClimate from './img/temperature.png'
import imgTuz from './img/tuz.png'
import imgAcilDurum from './img/acilDurum.png'
import binaSayici from './img/binaSayici.png'
import iklimBina from './img/iklimBina.png'
import refleksButonu from './img/refleksButonu.png'
import sensorPano from './img/sensorPano.png'
import tanidikAlarmi from './img/tanidikAlarmi.png'
import dengeOlcer from './img/dengeOlcer.png'
import lazerliVeri from './img/lazerliVeri.png'
import yapayKas from './img/yapayKas.png'
 import babyFire from './img/babyFire2.png'
 import hikayeOyunu from './img/hikayeYarismasi.png'

import ProjeThumb from './ProjeThumb';
import { Link } from 'react-router-dom'
 
function Projeler() {
    return (
        <div className="container  padding-small">

            <div className="row center"> <h2>Projeler</h2></div>
            <div className="row center large ">     
        
                <ProjeThumb title="Bina İklim Takip Sistemi" prototip="40" rapor="70" className="light-green" image={iklimBina} link="#" />
                <ProjeThumb title="Lazerli Veri İletimi" prototip="90" rapor="100"  className="light-green" image={lazerliVeri} link="#" />
                {/* <ProjeThumb title="Acil Durum Şebekesi" prototip="20" rapor="50"  className="red" image={imgAcilDurum} link="/proje/acil-durum-sebekesi" /> */}
                <ProjeThumb title="Tanıdık Alarmı" prototip="100" rapor="80"  className="green" image={tanidikAlarmi} link="#" />
                <ProjeThumb title="Bebek Ateş Takip" prototip="100" rapor="0"  className="light-green" image={babyFire} link="#" />
                <ProjeThumb title="Yemek Tuzu Ölçüm Sistemi" prototip="100" rapor="40"  className="light-green" image={imgTuz} link="#" />
                {/* <ProjeThumb title="Refleks Butonu" prototip="0" rapor="80"  className="red" image={refleksButonu} link="/proje/refleks-butonu" /> */}
                <ProjeThumb title="Bina Giriş Çıkış Sayıcısı" prototip="100" rapor="70"  className="light-green" image={binaSayici} link="#" />
                <ProjeThumb title="Oda Sensör Panosu" prototip="100" rapor="90"  className="green" image={sensorPano} link="#" />
                <ProjeThumb title="Denge Şapkası" prototip="90" rapor="80"  className="light-green" image={dengeOlcer} link="#" />
                <ProjeThumb title="Ortaklaşa Hikâye Yazma Oyunu" prototip="0" rapor="0"  className="red" image={hikayeOyunu} link="/proje/hikaye-yazma-oyunu" />
                {/* <ProjeThumb title="Yapay Kas" prototip="0" rapor="80"  className="red" image={yapayKas} link="/proje/yapay-kas" /> */}
                

            </div>

            <div className="row center"> <h2>İlgi Alanlarım:</h2></div>
            <div className="row center large">
                PHP, JS , CSS , HTML , NODEJS, WEBSOCKET,MQTT , MOSQUITTO, ESP8266 & ESP32 , LORAWAN , NGNIX , MYSQL, 
                ARDUINO , C/C++ , LINUX SERVER,  REACT, REACT-NATIVE, KICAD, SIMULIDE
            </div>


        </div>
    );
}

export default Projeler;