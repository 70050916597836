import React from 'react';
import ReactDOM from 'react-dom/client';
import{ BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import './index.css'
import App from './App.jsx';
import './css/tailwindOutput.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
       <App/>
    </>
);
